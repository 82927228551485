import styles from "./Answer.module.css";
import {getCitationData} from "./CitationData";
import {renderToStaticMarkup} from "react-dom/server";
import {useContext} from "react";
import {AppStateContext} from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
};

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean
): HtmlParsedAnswer {
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];
    const citations: string[] = [];
    const followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content);
        return "";
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]]]") {
                break;
            } else if (parsedAnswer[i] === "[[[") {
                lastIndex = i;
                break;
            }
        }
        parsedAnswer = parsedAnswer.substring(0, lastIndex);
    }

    const parts = parsedAnswer.split(/\[\[\[([^\]]+)\]\]\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part; // return unchanged text content
        } else {
            // store citations and return a clickable citation number
            const citationIndex = citations.includes(part) ? citations.indexOf(part) + 1 : citations.push(part);
            const { documentPath } = getCitationData(part);
            return renderToStaticMarkup(<a className={styles.supContainer} title={labels.chat.answer.ctaSource} href={documentPath} target="_blank">
                <sup className={styles.supCitation}>{citationIndex}</sup>
            </a>);
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions
    };
}
