const chatHistoryIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#106695"
      fillRule="evenodd"
      d="M0 1.994C0 .92.87.05 1.943.05h20.114C23.13.05 24 .92 24 1.994v20.114c0 1.073-.87 1.943-1.943 1.943H1.943A1.943 1.943 0 0 1 0 22.108V1.994Zm11.33-.115h10.841v20.343H11.33V1.88ZM9.5 22.222V1.88H1.829v20.343H9.5ZM3.25 5.477H8a.726.726 0 0 0 .527-.22.704.704 0 0 0 .223-.519c0-.2-.074-.373-.223-.519A.726.726 0 0 0 8 4H3.25a.726.726 0 0 0-.527.22.704.704 0 0 0-.223.518c0 .2.074.373.223.52.148.146.324.219.527.219Zm0 2H8c.203 0 .379.073.527.219.149.146.223.32.223.52s-.074.372-.223.518a.726.726 0 0 1-.527.22H3.25a.726.726 0 0 1-.527-.22.704.704 0 0 1-.223-.519c0-.2.074-.373.223-.519a.726.726 0 0 1 .527-.22Zm0 3.477H8c.203 0 .379.073.527.219.149.146.223.319.223.519s-.074.373-.223.52A.727.727 0 0 1 8 12.43H3.25a.727.727 0 0 1-.527-.22.705.705 0 0 1-.223-.519c0-.2.074-.373.223-.52a.727.727 0 0 1 .527-.218Z"
      clipRule="evenodd"
    />
  </svg>
);

export default chatHistoryIcon;
