import React, {useContext, useEffect, useState} from "react";
import { AppStateContext } from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";
import styles from "./Feedback.module.css";
import {Button} from "@fluentui/react-components";

import mailIcon from "../MailIcon/MailIcon";

export const Feedback: React.FC = () => {
  const appStateContext = useContext(AppStateContext);
  const labels = i18n[appStateContext?.state.lang || defaultLang];
  const feedbackRef = React.createRef<HTMLDivElement>();
  const feedbackButtonRef = React.createRef<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(false);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const handleFeedbackMenuBlur = (event: MouseEvent) => {
    if (feedbackRef.current
      && !feedbackRef.current.contains(event.target as Node)
      && !feedbackButtonRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  // close menu on ESC key or click outside the menu
  useEffect(() => {
    // prevent adding event listener if the menu is not open
    if (!feedbackRef.current || !isOpen) return;

    addEventListener("mousedown", handleFeedbackMenuBlur);
    addEventListener("keydown", handleEscape);

    return () => {
      removeEventListener("mousedown", handleFeedbackMenuBlur);
      removeEventListener("keydown", handleEscape);
    };
  }, [feedbackRef]);

  return (
    <div className={`${styles.collapsibleContainer} ${isOpen ? styles.open : ""}`}>
      <button className={styles.feedbackButton} onClick={() => setIsOpen(!isOpen)} ref={feedbackButtonRef}>
        <span className={styles.feedbackText}>Feedback</span>
      </button>
      {isOpen && (
        <div className={styles.openContent} ref={feedbackRef}>
          <p>
            {labels.feedback.text}
            <br/>{labels.feedback.sendEmail}
            <br/>
            <b><a className={styles.mailtoLink} href={labels.feedback.mailtoLink}>{labels.feedback.emailAddress}</a></b>
          </p>
          <div className={styles.shareButton}>
            <a
              id="sendEmail"
              href={labels.feedback.mailtoLink}
            >
              <Button className="mailIconButton" icon={mailIcon}/>
              {labels.feedback.sendFeedback}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
