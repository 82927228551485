import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";

import Logo from "../../assets/logo_xs.svg";
import {useContext} from "react";
import {AppStateContext} from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";

export const AnswerLoading = () => {
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];

    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <div className={styles.answerAndIdentifierContainer}>
            <div className={styles.answerIdentifier}>
                <img src={Logo} className={styles.logoIcon} />
            </div>
            <animated.div style={{ ...animatedStyles }}>
                <Stack className={styles.answerContainer}>
                    <Stack.Item grow>
                        <p className={styles.answerText}>
                            {labels.chat.answer.loading}
                            <span className={styles.loadingdots} />
                        </p>
                    </Stack.Item>
                </Stack>
            </animated.div>
        </div>
    );
};
