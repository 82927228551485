import * as React from "react";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Text,
    IconButton,
    List,
    PrimaryButton,
    Stack,
    ITextField
} from "@fluentui/react";

import { AppStateContext } from "../../state/AppProvider";
import { GroupedChatHistory } from "./ChatHistoryList";

import styles from "./ChatHistoryPanel.module.css";
import { useBoolean } from "@fluentui/react-hooks";
import {ChatConversationLoadingState, Conversation} from "../../api/models";
import {historyDelete, historyRead, historyRename} from "../../api";
import { useEffect, useRef, useState } from "react";

import ChatItemButton from "../ChatItemButton/ChatItemButton";
import deleteIcon from "../DeleteIcon/DeleteIcon";
import editIcon from "../EditIcon/EditIcon";
import classNames from "classnames";
import i18n, {defaultLang} from "../../i18n";

interface ChatHistoryListItemCellProps {
    item?: Conversation;
    onSelect: (item: Conversation | null) => void;
}

interface ChatHistoryListItemGroupsProps {
    groupedChatHistory: GroupedChatHistory[];
}

const formatMonth = (month: string) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [monthName, yearString] = month.split(" ");
    const year = parseInt(yearString);

    if (year === currentYear) {
        return monthName;
    } else {
        return month;
    }
};

export const ChatHistoryListItemCell: React.FC<ChatHistoryListItemCellProps> = ({ item, onSelect }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [edit, setEdit] = useState(false);
    const [editTitle, setEditTitle] = useState("");
    const [hideDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(true);
    const [errorDelete, setErrorDelete] = useState(false);
    const [renameLoading, setRenameLoading] = useState(false);
    const [errorRename, setErrorRename] = useState<string | undefined>(undefined);
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    const textFieldRef = useRef<ITextField | null>(null);

    const appStateContext = React.useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];

    const isSelected = item?.id === appStateContext?.state.currentChat?.id;
    const dialogContentProps = {
        type: DialogType.close,
        title: labels.history.deleteChatDialog.confirmation,
        closeButtonAriaLabel: labels.close,
        subText: labels.history.deleteChatDialog.text
    };

    const modalProps = {
        titleAriaId: "labelId",
        subtitleAriaId: "subTextId",
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
    };

    if (!item) {
        return null;
    }

    useEffect(() => {
        if (textFieldFocused && textFieldRef.current) {
            textFieldRef.current.focus();
            setTextFieldFocused(false);
        }
    }, [textFieldFocused]);

    useEffect(() => {
        if (appStateContext?.state.currentChat?.id !== item?.id) {
            setEdit(false);
            setEditTitle("");
        }
    }, [appStateContext?.state.currentChat?.id, item?.id]);

    const onDelete = async () => {
        let response = await historyDelete(item.id);
        if (!response.ok) {
            setErrorDelete(true);
            setTimeout(() => {
                setErrorDelete(false);
            }, 5000);
        } else {
            appStateContext?.dispatch({ type: "DELETE_CHAT_ENTRY", payload: item.id });
        }
        toggleDeleteDialog();
    };

    const onEdit = () => {
        setEdit(true);
        setTextFieldFocused(true);
        setEditTitle(item?.title);
    };

    const handleSelectItem = () => {
        // disable item selection while an answer is generating to prevent inconsistent conversation data in chat history.
        if (appStateContext?.state.isGenerating) {
            return;
        }
        onSelect(item);
        console.log("ITEM SELECTED: ", item);
        if (appStateContext?.state) {
            // reset possible error state from a previous chat
            appStateContext.state.error = null;
        }
        if (item.messages.length === 0) {
            fetchConversationFromHistory(item);
        } else {
            appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: item });
        }
    };

    const fetchConversationFromHistory = (item: Conversation) => {
        // TODO: since reading from history could take some time, should we introduce a "loading messages" state here also.
        //  show a loading spinner / animation in the chat window while messages are fetched? Ask Yuriy for UI/UX ideas.
        appStateContext?.dispatch({
            type: "UPDATE_CHAT_CONVERSATION_LOADING_STATE",
            payload: ChatConversationLoadingState.Loading
        });

        historyRead(item.id)
            .then(response => {
                if (response) {
                    const conversation = {
                        ...item,
                        messages: response?.messages || [],
                    };
                    appStateContext?.dispatch({type: "UPDATE_CURRENT_CHAT", payload: conversation});
                    appStateContext?.dispatch({type: "UPDATE_CHAT_HISTORY", payload: conversation});
                    appStateContext?.dispatch({
                        type: "UPDATE_CHAT_CONVERSATION_LOADING_STATE",
                        payload: ChatConversationLoadingState.Success
                    });
                } else {
                    appStateContext?.dispatch({
                      type: "UPDATE_CHAT_CONVERSATION_LOADING_STATE",
                      payload: ChatConversationLoadingState.Fail
                    });
                    console.error("There was an issue fetching conversation data from history.");
                }
            });
    }

    const handleSaveEdit = async (e: any) => {
        e.preventDefault();
        if (errorRename || renameLoading) {
            return;
        }
        if (editTitle == item.title) {
            setErrorRename(labels.history.renameErrorNoChange);
            setTimeout(() => {
                setErrorRename(undefined);
                setTextFieldFocused(true);
                if (textFieldRef.current) {
                    textFieldRef.current.focus();
                }
            }, 5000);
            return;
        }
        setRenameLoading(true);
        let response = await historyRename(item.id, editTitle);
        if (!response.ok) {
            setErrorRename(labels.history.renameError);
            setTimeout(() => {
                setTextFieldFocused(true);
                setErrorRename(undefined);
                if (textFieldRef.current) {
                    textFieldRef.current.focus();
                }
            }, 5000);
        } else {
            setRenameLoading(false);
            setEdit(false);
            appStateContext?.dispatch({ type: "UPDATE_CHAT_TITLE", payload: { ...item, title: editTitle } as Conversation });
            setEditTitle("");
        }
    };

    const chatHistoryTitleOnChange = (e: any) => {
        setEditTitle(e.target.value);
    };

    const cancelEditTitle = () => {
        setEdit(false);
        setEditTitle("");
    };

    const handleKeyPressEdit = (e: any) => {
        if (e.key === "Enter") {
            return handleSaveEdit(e);
        }
        if (e.key === "Escape") {
            cancelEditTitle();
            return;
        }
    };

    return (
        <Stack
            key={item.id}
            tabIndex={0}
          // disable item selection while an answer is generating to prevent inconsistent conversation data in chat history.
            className={classNames(styles.itemCell, {
                [styles.disabled]: appStateContext?.state.isGenerating
            })}
            onClick={() => handleSelectItem()}
            onKeyDown={e => (e.key === "Enter" || e.key === " " ? handleSelectItem() : null)}
            verticalAlign="center"
            // horizontal
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            styles={{
                root: {
                    backgroundColor: isSelected ? "#DEEDF5" : "transparent"
                }
            }}
        >
            {edit ? (
                <>
                    <Stack.Item style={{ width: "100%" }}>
                        <form onSubmit={e => handleSaveEdit(e)} className={styles.editTitleForm}>
                            <Stack horizontal verticalAlign={"center"}>
                                <Stack.Item style={{ width: "100%" }}>
                                    {/* <TextField
                                        componentRef={textFieldRef}
                                        autoFocus={textFieldFocused}
                                        value={editTitle}
                                        placeholder={item.title}
                                        onChange={chatHistoryTitleOnChange}
                                        onKeyDown={handleKeyPressEdit}
                                        // errorMessage={errorRename}
                                        disabled={errorRename ? true : false}
                                    /> */}
                                    <input
                                        // componentRef={textFieldRef}
                                        autoFocus={textFieldFocused}
                                        value={editTitle}
                                        // inputClassName={styles.editTitle}
                                        className={styles.editTitleWrapper}
                                        placeholder={item.title}
                                        onChange={chatHistoryTitleOnChange}
                                        onKeyDown={handleKeyPressEdit}
                                        // errorMessage={errorRename}
                                        disabled={errorRename ? true : false}
                                    />
                                </Stack.Item>
                                {editTitle && (
                                    <Stack.Item>
                                        <Stack aria-label="action button group" horizontal verticalAlign={"center"}>
                                            <IconButton
                                                role="button"
                                                disabled={errorRename !== undefined}
                                                onKeyDown={e => (e.key === " " || e.key === "Enter" ? handleSaveEdit(e) : null)}
                                                onClick={e => handleSaveEdit(e)}
                                                aria-label="confirm new title"
                                                iconProps={{ iconName: "CheckMark" }}
                                                styles={{ root: { color: "#106695", fontSize: "18px", fontWeight: "600" } }}
                                            />
                                            <IconButton
                                                role="button"
                                                disabled={errorRename !== undefined}
                                                onKeyDown={e => (e.key === " " || e.key === "Enter" ? cancelEditTitle() : null)}
                                                onClick={() => cancelEditTitle()}
                                                aria-label="cancel edit title"
                                                iconProps={{ iconName: "Cancel" }}
                                                styles={{ root: { color: "#106695", fontSize: "18px", fontWeight: "600" } }}
                                            />
                                        </Stack>
                                    </Stack.Item>
                                )}
                            </Stack>
                            {errorRename && (
                                <Text role="alert" aria-label={errorRename} style={{ fontSize: 12, fontWeight: 400, color: "rgb(164,38,44)" }}>
                                    {errorRename}
                                </Text>
                            )}
                        </form>
                    </Stack.Item>
                </>
            ) : (
                <>
                    <Stack
                        horizontal
                        verticalAlign={"center"}
                        style={{ width: "100%", display: "flex", justifyContent: "space-between", borderRadius: 0, padding: "5px 10px" }}
                    >
                        <div className={styles.chatTitle}>{item.title}</div>
                        {(isSelected || isHovered) && (
                            <Stack horizontal horizontalAlign="end">
                                <ChatItemButton
                                    icon={editIcon}
                                    title={labels.history.editTitle}
                                    onClick={onEdit}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => (e.key === " " ? onEdit() : null)}
                                />
                                <ChatItemButton
                                    icon={deleteIcon}
                                    title={labels.history.deleteChat}
                                    onClick={toggleDeleteDialog}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => (e.key === " " ? toggleDeleteDialog() : null)}
                                />
                            </Stack>
                        )}
                    </Stack>
                </>
            )}
            {errorDelete && (
                <Text
                    styles={{
                        root: { color: "red", marginTop: 5, fontSize: 14 }
                    }}
                >
                    Error: could not delete item
                </Text>
            )}
            <Dialog hidden={hideDeleteDialog} onDismiss={toggleDeleteDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={onDelete} text={labels.history.deleteChat} />
                    <DefaultButton onClick={toggleDeleteDialog} text={labels.cancel} />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};

export const ChatHistoryListItemGroups: React.FC<ChatHistoryListItemGroupsProps> = ({ groupedChatHistory }) => {
    const [, setSelectedItem] = React.useState<Conversation | null>(null);

    const handleSelectHistory = (item?: Conversation) => {
        if (item) {
            setSelectedItem(item);
        }
    };

    const onRenderCell = (item?: Conversation) => {
        return <ChatHistoryListItemCell item={item} onSelect={() => handleSelectHistory(item)} />;
    };

    return (
        <div className={styles.listContainer}>
            {groupedChatHistory.map(
                group =>
                    group.entries.length > 0 && (
                        <Stack
                            horizontalAlign="start"
                            verticalAlign="center"
                            key={group.month}
                            className={styles.chatGroup}
                            aria-label={`chat history group: ${group.month}`}
                        >
                            <Stack aria-label={group.month} className={styles.chatMonth}>
                                {formatMonth(group.month)}
                            </Stack>
                            {/* disable list virtualization by onShouldVirtualize => false, because list is not visible initially - thus sometimes miscalculates rendering of virtual pages.
                              * list virtualization is not required anyway, since old entries are purged in cosmosdb after a certain amount of time.
                              * @see: https://github.com/microsoft/fluentui/issues/16403
                              * @see: https://codepen.io/khmakoto/pen/OJbrGNK?editors=0010 */}
                            <List aria-label={`chat history list`} items={group.entries} onRenderCell={onRenderCell} className={styles.chatList} onShouldVirtualize={() => false} />
                        </Stack>
                    )
            )}
        </div>
    );
};
