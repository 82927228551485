const warningIcon = (
  <svg className="warning-icon" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.00078 3.82743L1.61843 14.6283H14.3831L8.00078 3.82743ZM8.53058 2.90954C8.29239 2.50644 7.70917 2.50644 7.47098 2.90954L0.549412 14.623C0.307006 15.0332 0.602721 15.5514 1.07921 15.5514H14.9224C15.3988 15.5514 15.6946 15.0332 15.4522 14.623L8.53058 2.90954Z"
          fill="#CA8400"/>
    <rect x="7.38477" y="12.4745" width="1.23077" height="1.23077" rx="0.5" fill="#CA8400"/>
    <rect x="7.38477" y="6.93603" width="1.23077" height="4.92308" rx="0.5" fill="#CA8400"/>
  </svg>
);

export default warningIcon;