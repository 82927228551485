import styles from "./UserChatMessage.module.css";

import userIcon from "../UserIcon/UserIcon"
import React, {useContext} from "react";
import {AppStateContext} from "../../state/AppProvider";
interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div className={styles.container}>
            <div className={styles.message}>{message}</div>
            <div className={styles.userIdentifier}>
              {appStateContext?.state.userPicture
                ? <img src={appStateContext?.state.userPicture} className={styles.userPicture}/>
                : userIcon}
            </div>
        </div>
    );
};
