import React, { ReactNode } from 'react';
import {MathJaxContext } from 'better-react-mathjax';
import {MathJaxConfig} from "../../mathJaxUtils";

type MathJaxProviderProps = {
  children: ReactNode;
};

const MathJaxProvider: React.FC<MathJaxProviderProps> = ({ children }) => {
  return (
    <MathJaxContext config={MathJaxConfig}>
      {children}
    </MathJaxContext>
  );
};

export default MathJaxProvider;