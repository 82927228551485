import {getFullDocumentPath} from "../../api";

export function getCitationData(citation: string): any {
  let [filename, extension] = citation.split(".");
  let pageNumber = null;
  let pageAnchor = '';

  const lastHyphenIndex = filename.lastIndexOf("-");
  if (lastHyphenIndex > 0) {
    const chunkNumber: number = parseInt(filename.substring(lastHyphenIndex + 1));
    if (!isNaN(chunkNumber) && chunkNumber >= 0) {
      const isPdf = extension.toLowerCase() === 'pdf';
      pageNumber = chunkNumber + 1;
      pageAnchor = isPdf ? `#page=${pageNumber}` : '';
      filename = filename.substring(0, lastHyphenIndex);
    }
  }

  const documentPath = getFullDocumentPath(`${filename}.${extension}${pageAnchor}`);

  return { filename, pageNumber, documentPath };
}