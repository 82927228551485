import {useContext, useMemo} from "react";
import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import {ChatAppResponse} from "../../api";
import {getCitationData} from "./CitationData";
import { parseAnswerToHtml } from "./AnswerParser";
import Logo from "../../assets/logo_xs.svg";
import MathJaxRenderer from "../MathJax/MathJaxRenderer";
import {containsLatexMath} from "../../mathJaxUtils";
import {AppStateContext} from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    // onThoughtProcessClicked: () => void;
    // onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string, id?: string) => void;
    conversationId?: string;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    // onThoughtProcessClicked,
    // onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];

    const messageContent = answer.choices[0].message.content;
    const parsedAnswer = useMemo(
        () =>
            parseAnswerToHtml(
                messageContent,
                isStreaming
            ),
        [answer]
    );
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml, {
        ADD_ATTR: ['target']
    });
    return (
        <div>
            <div className={styles.answerAndIdentifierContainer}>
                <div className={styles.answerIdentifier}>
                    <img src={Logo} className={styles.logoIcon} />
                </div>
                <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                    <div>
                        <Stack.Item grow>
                            <div className={styles.answerText} >
                                {/* only render with MathJax when LaTeX math code is in the answer */}
                                { containsLatexMath(sanitizedAnswerHtml)
                                  ? <MathJaxRenderer content={sanitizedAnswerHtml} isStreaming={isStreaming}/>
                                  : <div dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }} />
                                }
                            </div>
                        </Stack.Item>

                        {!!parsedAnswer.citations.length && (
                            <Stack.Item>
                                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                    <span className={styles.citationLearnMore}>{labels.chat.answer.sources}:</span>
                                    {parsedAnswer.citations.map((citation, i) => {
                                        const { filename, pageNumber, documentPath } = getCitationData(citation);
                                        return (
                                            <a key={i} className={styles.citation} title={labels.chat.answer.ctaSource} href={documentPath} target="_blank" >
                                                {`${++i}. ${filename}`}
                                                {pageNumber ? ` - ${labels.chat.answer.page} ${pageNumber}` : ""}
                                            </a>
                                        );
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}
                    </div>
                    {/*<IconButton
                        className={styles.supportingContentButton}
                        style={{ color: "black" }}
                        iconProps={{ iconName: "Lightbulb" }}
                        title="Zeige die verwendeten Inhalte"
                        ariaLabel="Show supporting content"
                        onClick={() => onSupportingContentClicked()}
                        disabled={!answer.choices[0].context.data_points?.length}
                    />*/}
                </Stack>
            </div>
            <Stack className={`${styles.followupQuestionsContainer}`} verticalAlign="space-between">
                {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <div className={styles.followupQuestionLearnMore}>{labels.chat.answer.followUpQuestions}:</div>
                            {parsedAnswer.followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
            </Stack>
        </div>
    );
};
