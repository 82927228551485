export const MathJaxConfig = {
  loader: { load: ['input/tex', 'output/chtml'] },
  tex: {
    inlineMath: [['$', '$'], ['\\(', '\\)']]
  },
  chtml: {
    displayAlign: 'left'
  }
};

// check if content contains LaTeX math code
export const containsLatexMath = (content: string) => {
  return !!content.match(/\$|\\\(|\\\[|\\begin\{[^}]*}/);
}

// check if content contains complete LaTeX math code
export const containsCompleteLatexMath = (content: string) => {
  const latexMathPattern = /\$[^$]*\$|\\\([^)]*\\\)|\\\[[^\]]*\\\]/g
  return latexMathPattern.test(content);
}

