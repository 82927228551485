import React from "react";
import {Outlet} from "react-router-dom";

import styles from "./Layout.module.css";
import {DisclaimerModal} from "../../components/DisclaimerModal";
import {PageHeader} from "../../components/PageHeader";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <DisclaimerModal/>
            <PageHeader/>
            <Outlet/>
        </div>
    );
};

export default Layout;
