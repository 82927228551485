import React, { createContext, useReducer, ReactNode, useEffect } from "react";
import { appStateReducer } from "./AppReducer";
import {
    ChatHistoryLoadingState,
    CosmosDBHealth,
    historyList,
    historyEnsure,
    CosmosDBStatus,
    ChatConversationLoadingState, getUserPictureUrl, getUsername
} from "../api";
import { Conversation } from "../api";

export interface AppState {
    lang : string;
    isChatHistoryOpen: boolean;
    isDisclaimerModalOpen?: boolean;
    isChatSettingsOpen?: boolean;
    isUserMenuOpen?: boolean;
    isGenerating?: boolean;
    chatConversationLoadingState: ChatConversationLoadingState;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
    enableFollowUpQuestions?: boolean;
    retrieveCount?: number;
    userPicture?: string | null;
    userName?: string | null;
    error?: string | null;
}

export type Action =
    | { type: "TOGGLE_CHAT_HISTORY" }
    | { type: "DISCLAIMER_MODAL_OPEN"; payload?: boolean }
    | { type: "CHAT_SETTINGS_OPEN"; payload?: boolean }
    | { type: "USER_MENU_OPEN"; payload?: boolean }
    | { type: "SET_COSMOSDB_STATUS"; payload: CosmosDBHealth }
    | { type: "SET_GENERATING"; payload?: boolean }
    | { type: "UPDATE_CHAT_CONVERSATION_LOADING_STATE"; payload: ChatConversationLoadingState }
    | { type: "UPDATE_CHAT_HISTORY_LOADING_STATE"; payload: ChatHistoryLoadingState }
    | { type: "UPDATE_CURRENT_CHAT"; payload: Conversation | null }
    | { type: "UPDATE_FILTERED_CHAT_HISTORY"; payload: Conversation[] | null }
    | { type: "ENABLE_FOLLOW_UP_QUESTIONS"; payload?: boolean }
    | { type: "SET_RETRIEVE_COUNT"; payload: number }
    | { type: "UPDATE_CHAT_HISTORY"; payload: Conversation } // API Call
    | { type: "UPDATE_CHAT_TITLE"; payload: Conversation } // API Call
    | { type: "DELETE_CHAT_ENTRY"; payload: string } // API Call
    | { type: "DELETE_CHAT_HISTORY" } // API Call
    | { type: "DELETE_CURRENT_CHAT_MESSAGES"; payload: string } // API Call
    | { type: "FETCH_CHAT_HISTORY"; payload: Conversation[] | null }
    | { type: "USER_PICTURE"; payload: string | null }
    | { type: "USER_NAME"; payload: string | null };

const initialState: AppState = {
    lang: "de", // default language, will be 'en' later
    isChatHistoryOpen: false,
    isDisclaimerModalOpen: false,
    isChatSettingsOpen: false,
    isUserMenuOpen: false,
    isGenerating: false,
    chatConversationLoadingState: ChatConversationLoadingState.NotStarted,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    enableFollowUpQuestions: false,
    retrieveCount: 5,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured
    },
    userPicture: null,
    userName: null,
    error: null
};

export const AppStateContext = createContext<
    | {
          state: AppState;
          dispatch: React.Dispatch<Action>;
      }
    | undefined
>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);

    useEffect(() => {

        getUsername().then(response => {
            dispatch({ type: "USER_NAME", payload: response });
        });

        getUserPictureUrl().then(response => {
            dispatch({ type: "USER_PICTURE", payload: response });
        });

        // Check for cosmosdb config and fetch initial data here
        // Note: historyList is only fetching a list of conversations, without the messages
        const fetchChatHistory = async (): Promise<Conversation[] | null> => {
            const result = await historyList()
                .then(response => {
                    dispatch({ type: "FETCH_CHAT_HISTORY", payload: response ? response : null });
                    return response;
                })
                .catch(err => {
                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
                    console.error("There was an issue fetching your data.");
                    return null;
                });
            return result;
        };

        const getHistoryEnsure = async () => {
            dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Loading });
            historyEnsure()
                .then(response => {
                    if (response?.cosmosDB) {
                        fetchChatHistory()
                            .then(res => {
                                if (res) {
                                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Success });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                                } else {
                                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                                }
                            })
                            .catch(err => {
                                dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                                dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                            });
                    } else {
                        dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                        dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
                    }
                })
                .catch(err => {
                    dispatch({ type: "UPDATE_CHAT_HISTORY_LOADING_STATE", payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: "SET_COSMOSDB_STATUS", payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
                });
        };
        getHistoryEnsure();
    }, []);

    return <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>;
};
