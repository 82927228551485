import React, {useContext, useEffect} from "react";
import {AppStateContext} from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";
import {addEventListener, removeEventListener} from "../../utils/documentEventHandlers";
import {Button} from "@fluentui/react-components";
import {Checkbox, SpinButton} from "@fluentui/react";
import classNames from "classnames";
import styles from "./ChatSettingsMenu.module.css";
import filterIcon from "../FilterIcon/FilterIcon";

interface Props {
  buttonLabel?: string;
}

const ChatSettingsMenu = ({buttonLabel}: Props) => {
  const appStateContext = useContext(AppStateContext);
  const labels = i18n[appStateContext?.state.lang || defaultLang];
  const chatSettingsMenuRef = React.createRef<HTMLDivElement>();
  const chatSettingsButtonRef = React.createRef<HTMLDivElement>();

  const onEnableFollowUpQuestionsChange = (ev: any, checked: boolean | undefined) => {
    appStateContext?.dispatch({type: "ENABLE_FOLLOW_UP_QUESTIONS", payload: !!checked});
  }

  const onRetrieveCountChange = (ev: any, value: string | undefined) => {
    const retrieveCount = parseInt(value || "5");
    appStateContext?.dispatch({type: "SET_RETRIEVE_COUNT", payload: retrieveCount});
  }

  {/* NOTE: these are old props and handlers from the original app, from Chat.tsx.
    * @see: https://github.com/Azure-Samples/azure-search-openai-demo */
  }
  /* const [promptTemplate, setPromptTemplate] = useState<string>("");
      const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
      const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
      const [shouldStream, setShouldStream] = useState<boolean>(true);
      const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
      const [excludeCategory, setExcludeCategory] = useState<string>("");
      const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
      const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
  const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setPromptTemplate(newValue || "");
  };

  const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
    setRetrievalMode(option?.data || RetrievalMode.Hybrid);
  };

  const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseSemanticRanker(!!checked);
  };

  const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseSemanticCaptions(!!checked);
  };

  const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setShouldStream(!!checked);
  };

  const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
    setExcludeCategory(newValue || "");
  };

  const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseOidSecurityFilter(!!checked);
  };

  const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseGroupsSecurityFilter(!!checked);
  };*/

  const toggleChatSettings = () => {
    appStateContext?.dispatch({type: "CHAT_SETTINGS_OPEN", payload: !appStateContext?.state.isChatSettingsOpen});
  }

  const closeChatSettingsMenu = () => {
    appStateContext?.dispatch({type: "CHAT_SETTINGS_OPEN", payload: false});
  }

  const handleEscapeKeypress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeChatSettingsMenu();
    }
  };

  const handleMenuBlurClick = (event: MouseEvent) => {
    if (chatSettingsMenuRef.current
      && !chatSettingsMenuRef.current.contains(event.target as Node)
      && !chatSettingsButtonRef.current?.contains(event.target as Node)
    ) {
      closeChatSettingsMenu();
    }
  };

  // close menu on ESC key or click outside the menu
  useEffect(() => {
    // prevent adding event listener if the menu is not open
    if (!chatSettingsMenuRef.current || !appStateContext?.state.isChatSettingsOpen) return;

    addEventListener("keydown", handleEscapeKeypress);
    addEventListener("mousedown", handleMenuBlurClick);

    return () => {
      removeEventListener("mousedown", handleMenuBlurClick);
      removeEventListener("keydown", handleEscapeKeypress);
    };
  }, [chatSettingsMenuRef]);

  return (
    <>
      <div ref={chatSettingsButtonRef}>
        <Button className={styles.chatSettingsButton} onClick={toggleChatSettings} icon={filterIcon}>
          {buttonLabel}
        </Button>
      </div>

      <div className={classNames(styles.chatSettingsMenu, {
        [styles.open]: appStateContext?.state.isChatSettingsOpen,
      })} ref={chatSettingsMenuRef}>
        <Checkbox
          className={styles.chatSettingsSeparator}
          checked={appStateContext?.state.enableFollowUpQuestions}
          label={labels.chat.settings.followUpQuestions}
          onChange={onEnableFollowUpQuestionsChange}
        />

        <SpinButton
          className={styles.chatSettingsSeparator}
          label={labels.chat.settings.retrieveCount}
          min={1}
          max={10}
          defaultValue={appStateContext?.state.retrieveCount?.toString()}
          onChange={onRetrieveCountChange}
        />

        {/* NOTE: these are old options and settings from the original app, from Chat.tsx.
          * client decided only to provide above settings for the users.
          * @see: https://github.com/Azure-Samples/azure-search-openai-demo */}
        {/*
        <TextField
                              className={styles.chatSettingsSeparator}
                              defaultValue={promptTemplate}
                              label="Override prompt template"
                              multiline
                              autoAdjustHeight
                              onChange={onPromptTemplateChange}
                          />
                          <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                          <Checkbox
                              className={styles.chatSettingsSeparator}
                              checked={useSemanticRanker}
                              label="Use semantic ranker for retrieval"
                              onChange={onUseSemanticRankerChange}
                          />
                          <Checkbox
                              className={styles.chatSettingsSeparator}
                              checked={useSemanticCaptions}
                              label="Use query-contextual summaries instead of whole documents"
                              onChange={onUseSemanticCaptionsChange}
                              disabled={!useSemanticRanker}
                          />*/}
        {/*{useLogin && (
                              <Checkbox
                                  className={styles.chatSettingsSeparator}
                                  checked={useOidSecurityFilter}
                                  label="Use oid security filter"
                                  disabled={!client?.getActiveAccount()}
                                  onChange={onUseOidSecurityFilterChange}
                              />
                          )}
                          {useLogin && (
                              <Checkbox
                                  className={styles.chatSettingsSeparator}
                                  checked={useGroupsSecurityFilter}
                                  label="Use groups security filter"
                                  disabled={!client?.getActiveAccount()}
                                  onChange={onUseGroupsSecurityFilterChange}
                              />
                          )}*/}
        {/*<Dropdown
                              className={styles.chatSettingsSeparator}
                              label="Retrieval mode"
                              options={[
                                  { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                                  { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                                  { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                              ]}
                              required
                              onChange={onRetrievalModeChange}
                          />
                          <Checkbox
                              className={styles.chatSettingsSeparator}
                              checked={shouldStream}
                              label="Stream chat completion responses"
                              onChange={onShouldStreamChange}
                          />*/}
        {/*{useLogin && <TokenClaimsDisplay />}*/}
      </div>
    </>
  );
}

export default ChatSettingsMenu;