const SendIcon = (
    <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.532 0C23.3891 0 23.2151 0.0405469 23.0141 0.125648L0.639344 9.61992C-0.166156 9.96157 -0.217156 10.6204 0.526094 11.0836L6.84859 15.0218C7.59184 15.4846 8.57472 16.4768 9.03259 17.226L12.8227 23.4315C13.0402 23.7874 13.3031 23.964 13.5558 23.964C13.8352 23.964 14.1015 23.7495 14.283 23.3258L23.8515 0.972422C24.1106 0.366023 23.9629 0 23.532 0ZM7.63014 13.6857L2.50539 10.4933L19.6995 3.1984L8.63252 14.4529C8.29427 14.1529 7.95077 13.8851 7.63014 13.6857ZM13.4231 21.4515L10.3661 16.4471C10.1858 16.1516 9.94764 15.8366 9.68212 15.5239L20.8005 4.218L13.4231 21.4515Z"
            fill="#106695"
        />
    </svg>
);

export default SendIcon;
