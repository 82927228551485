const de_labels = {
  appTitle: "AI-Powered THEMIS",

  cancel: "Abbrechen",
  close: "Schließen",
  notice: "Hinweis",
  errorMessage: "Es ist ein Fehler aufgetreten.",
  startNewChat: "Neuen Chat starten",
  tryAgain: "Nochmal versuchen",

  pageHeader: {
    themisUrl: "https://www.themis-wissen.de",
  },

  pageFooter: {

  },

  chat: {
    emptyStateSubtitle: "Stelle mir eine Frage oder probiere ein Beispiel aus",
    exampleQuestions: [
      "Welche Projekte haben die Zahnfußtragfähigkeit von Zahnrädern untersucht?",
      "Was war das Forschungsziel des Projektes WL 866?",
      "Wie wirkt sich das Lagerbetriebsspiel auf das Ringwandern aus?",
    ],
    errorMessages: {
      content_filter: "THEMIS AI hat Ihre Anfrage abgelehnt, weil unsere automatischen Filter sie als potenziell unangemessen eingestuft haben. Unser System ist vorsichtig, um problematische Inhalte zu vermeiden. Manchmal werden dadurch aber auch eigentlich unbedenkliche Anfragen blockiert. Versuchen Sie bitte, Ihre Anfrage anders zu formulieren, oder wenden Sie sich bitte via Feedback-Button an uns, wenn das Problem weiterhin besteht.",
      openai_error: "THEMIS AI konnte aufgrund eines technischen Problems bei der Anfrage an den AI-Service keine Antwort generieren. Bitte versuchen Sie es erneut. Falls das Problem weiterbesteht, wenden Sie sich bitte via Feedback-Button an uns.",
      generic_error: "Entschuldigen Sie bitte, hier ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es noch einmal. Falls das Problem weiterhin besteht, teilen Sie uns den Fehler bitte über den Feedback-Button mit."
    },
    errorSaveToDB: "Entschuldigen Sie bitte, der Chatverlauf kann aktuell nicht gespeichert werden. Bitte versuchen Sie es später noch einmal. Falls das Problem weiterhin besteht, teilen Sie uns den Fehler bitte über den Feedback-Button mit.",
    question: {
      inputPlaceholder: "Stelle eine Frage",
    },
    answer: {
      followUpQuestions: "Anregungen für weitere Fragen",
      loading: "Antwort wird generiert",
      mathDisclaimer: "Die Ausgabe mathematischer Formeln und Symbole kann von der Originalquelle abweichen. Bitte überprüfen Sie daher immer die Quellen, bevor Sie Formeln verwenden.",
      mathScrollHint: "Nach rechts scrollen, um die gesamte Formel anzuzeigen",
      sources: "Quellen",
      ctaSource: "Hier klicken, um das Quelldokument anzuzeigen",
      page: "Seite",
    },
    settings: {
      buttonLabel: "Chat Einstellungen",
      followUpQuestions: "Folgefragen vorschlagen",
      retrieveCount: "Max. berücksichtigte Quellen"
    }
  },

  feedback: {
    text: "Vielen Dank für die Verwendung unseres Chatbots! Wenn Sie Verbesserungsvorschläge oder Fehlermeldungen haben, würden wir uns freuen, von Ihnen zu lesen.",
    sendEmail: "Senden Sie eine E-Mail an:",
    emailAddress: "feedback-themis-ai@fva-net.de",
    mailtoLink: "mailto:feedback-themis-ai@fva-net.de?subject=Feedback%20THEMIS%20AI%20Chatbot&body=Verbesserungsvorschlag%20%2F%20Fehlerbeschreibung:%0A%0AScreenshots%20%2F%20Chatverlauf:%0A",
    sendFeedback: "Feedback senden"
  },

  history: {
    title: "Chatverlauf",
    loading: "Lade Chatverlauf...",
    loadingError: "Beim Laden des Chatverlaufs ist ein Fehler aufgetreten.",
    cantSave: "Der Chatverlauf kann aktuell nicht gespeichert werden.",
    search: {
      placeholder: "Suche"
    },
    clearAllHistory: "Gesamten Chatverlauf löschen",
    clearAll: "Alles löschen",
    clearAllDialog: {
      confirmation: "Möchten Sie den gesamten Chatverlauf wirklich löschen?",
      error: "Beim Löschen des Chatverlaufs ist ein Fehler aufgetreten.",
      text: "Der gesamte Chatverlauf wird gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
      tryAgain: "Bitte erneut versuchen. Wenn das Problem weiterhin besteht, Senden Sie eine E-Mail an: feedback-themis-ai@fva-net.de."
    },
    deleteChatDialog: {
      confirmation: "Möchten Sie diesen Chat wirklich löschen?",
      text: "Der ausgewählte Chat wird gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    deleteChat: "Chat löschen",
    editTitle: "Titel bearbeiten",
    hide: "Chatverlauf ausblenden",
    show: "Chatverlauf anzeigen",
    newChat: "Neuer Chat",
    noChatHistory: "Kein Chatverlauf verfügbar.",
    recent: "Aktuell",
    renameErrorNoChange: "Fehler: Bitte einen neuen Titel eingeben.",
    renameError: "Fehler: Der Chatverlauf konnte nicht umbenannt werden.",
  },

  userMenu: {
    logout: "Logout",
    profile: "Mein Profil",
    profileUrl: "https://www.themis-wissen.de/#/portal/profile",
    links: {
      disclaimer: "Disclaimer und Haftungsausschluss",
      imprint: "Impressum",
      imprintUrl: "https://www.themis-wissen.de/#/imprint",
      privacyPolicy: "Datenschutz",
      privacyPolicyUrl: "https://www.themis-wissen.de/#/privacy-policy",
      sitePolicy: "Nutzungsbedingungen",
      sitePolicyUrl: "https://www.themis-wissen.de/#/site-policy"
    }
  }
}

export default de_labels;