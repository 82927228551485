import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IStackStyles,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text
} from "@fluentui/react";

import { Conversation } from "../../api";
import { useBoolean } from "@fluentui/react-hooks";

import styles from "./ChatHistoryPanel.module.css";
import { useContext, useRef, useState, useEffect } from "react";
import { AppStateContext } from "../../state/AppProvider";
import React from "react";
import ChatHistoryList from "./ChatHistoryList";
import { ChatHistoryLoadingState, historyDeleteAll } from "../../api";

import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

import ChatItemButton from "../ChatItemButton/ChatItemButton";
import chatHistoryIcon from "../ChatHistoryIcon/ChatHistoryIcon";
import deleteIcon from "../DeleteIcon/DeleteIcon";
import plusIcon from "../PlusIcon/plusIcon";
import i18n, {defaultLang} from "../../i18n";

interface ChatHistoryPanelProps {
    newChat: () => void;
    newChatDisabled: boolean;
}

const commandBarButtonStyle: Partial<IStackStyles> = { root: { height: "28px" } };

export function ChatHistoryPanel({newChat, newChatDisabled}: ChatHistoryPanelProps) {
    const appStateContext = useContext(AppStateContext);
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const [hideClearAllDialog, { toggle: toggleClearAllDialog }] = useBoolean(true);
    const [clearing, setClearing] = useState(false);
    const [clearingError, setClearingError] = useState(false);

    // search
    const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    // Resizing state
    const [width, setWidth] = useState(55); // Initial width when closed
    const [lastOpenWidth, setLastOpenWidth] = useState(300); // Store last opened width
    const [isResizing, setIsResizing] = useState(false);
    const resizerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const labels = i18n[appStateContext?.state.lang || defaultLang];

    const clearAllDialogContentProps = {
        type: DialogType.close,
        title: !clearingError ? labels.history.clearAllDialog.confirmation : labels.history.clearAllDialog.error,
        closeButtonAriaLabel: labels.close,
        subText: !clearingError
            ? labels.history.clearAllDialog.text
            : labels.history.clearAllDialog.tryAgain,
    };

    const modalProps = {
        titleAriaId: "labelId",
        subtitleAriaId: "subTextId",
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
    };

    const handleHistoryClick = () => {
        const isCurrentlyOpen = appStateContext?.state.isChatHistoryOpen;
        const newWidth = isCurrentlyOpen ? 55 : lastOpenWidth;
        setWidth(newWidth);
        appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
    };

    const client = useLogin ? useMsal().instance : undefined;

    const onClearAllChatHistory = async () => {
        setClearing(true);

        const token = client ? await getToken(client) : undefined;

        let response = await historyDeleteAll(token?.accessToken);
        if (!response.ok) {
            setClearingError(true);
        } else {
            appStateContext?.dispatch({ type: "DELETE_CHAT_HISTORY" });
            toggleClearAllDialog();
        }
        setClearing(false);
    };

    const onHideClearAllDialog = () => {
        toggleClearAllDialog();
        setTimeout(() => {
            setClearingError(false);
        }, 2000);
    };

    //Filter conversations based on search term
    const searchConversations = (term: string) => {
        const chatHistory = appStateContext?.state.chatHistory || []; // Ensure chatHistory is never null or undefined

        if (!term) {
            setFilteredConversations(chatHistory); // Pass chatHistory or [] when term is empty
        } else {
            // NOTE: this can only search for titles not messages from database, as not all messages are not loaded in frontend
            // if also messages should be found, a proper backend endpoint should be implemented
            const filtered = chatHistory.filter(
                convo => convo.title.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredConversations(filtered);
        }
    };

    // Update filtered conversations whenever chat history or search term changes
    useEffect(() => {
        searchConversations(searchTerm);
    }, [appStateContext?.state.chatHistory, searchTerm]);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsResizing(true);
        if (containerRef.current) {
            containerRef.current.classList.add("resizing");
        }
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsResizing(true);
        if (containerRef.current) {
            containerRef.current.classList.add("resizing");
        }
    };

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (isResizing) {
                if (containerRef.current && resizerRef.current) {
                    const newWidth = event.clientX;
                    const clampedWidth = Math.min(Math.max(newWidth, 55), window.innerWidth * 0.5);
                    setWidth(clampedWidth);
                    setLastOpenWidth(clampedWidth);
                }
            }
        };

        const handleTouchMove = (event: TouchEvent) => {
            if (isResizing) {
                if (containerRef.current && resizerRef.current) {
                    const touch = event.touches[0];
                    const newWidth = touch.clientX;
                    const clampedWidth = Math.min(Math.max(newWidth, 55), window.innerWidth * 0.5);
                    setWidth(clampedWidth);
                    setLastOpenWidth(clampedWidth);
                }
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
            if (containerRef.current) {
                containerRef.current.classList.remove("resizing");
            }
        };

        const handleTouchEnd = () => {
            setIsResizing(false);
            if (containerRef.current) {
                containerRef.current.classList.remove("resizing");
            }
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, [isResizing, appStateContext]);

    useEffect(() => {
        const historyWidth = appStateContext?.state.isChatHistoryOpen ? width : 55;
        document.documentElement.style.setProperty("--chat-history-width", `${historyWidth}px`);
    }, [width, appStateContext?.state.isChatHistoryOpen]);

    return (
        <section
            ref={containerRef}
            style={{
                width: `${width}px`,
                maxWidth: "50vw"
            }}
            className={`${styles.container} ${appStateContext?.state.isChatHistoryOpen ? styles.containerOpen : styles.containerClosed}`}
            data-is-scrollable={true}
            aria-label={"chat history panel"}
        >
            <Stack className={styles.chatHistoryHeader} horizontal horizontalAlign="space-between" verticalAlign="start" wrap aria-label="chat history header">
                {appStateContext?.state.isChatHistoryOpen && (
                    <StackItem>
                        <Text
                            role="heading"
                            aria-level={2}
                            style={{
                                alignSelf: "center",
                                fontWeight: "400",
                                fontSize: "20px",
                                marginRight: "auto",
                                lineHeight: "28px",
                                fontStyle: "normal",
                                fontFamily: "Open Sans"
                            }}
                        >
                            {labels.history.title}
                        </Text>
                    </StackItem>
                )}
                <Stack verticalAlign="start">
                    <Stack horizontal styles={commandBarButtonStyle}>
                        {appStateContext?.state.isChatHistoryOpen && (
                            <ChatItemButton
                              icon={deleteIcon}
                              title={labels.history.clearAllHistory}
                              onClick={toggleClearAllDialog}
                              ariaLabel={labels.history.clearAllHistory}
                              disabled={appStateContext?.state.isGenerating}
                            />
                        )}
                        <ChatItemButton
                            icon={chatHistoryIcon}
                            title={appStateContext?.state.isChatHistoryOpen ? labels.history.hide : labels.history.show}
                            onClick={handleHistoryClick}
                            ariaLabel={appStateContext?.state.isChatHistoryOpen ? labels.history.hide : labels.history.show}
                            disabled={appStateContext?.state.isGenerating}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack verticalAlign="start" horizontalAlign="center" className={styles.newChatButtonContainer}>
                <ChatItemButton
                  icon={plusIcon}
                  title={labels.history.newChat}
                  onClick={newChat}
                  ariaLabel={labels.history.newChat}
                  className={styles.newChatButton}
                  disabled={newChatDisabled}
                  appearance={"outline"}
                  showLabel={appStateContext?.state.isChatHistoryOpen}
                />
            </Stack>
            {appStateContext?.state.isChatHistoryOpen && (
                <Stack
                    aria-label="chat history panel content"
                    styles={{
                        root: {
                            display: "flex",
                            flexGrow: 1,
                            flexDirection: "column",
                            paddingTop: "2.5px",
                            width: "100%",
                            padding: "1px 15px"
                        }
                    }}
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        flexWrap: "wrap"
                    }}
                >
                    <input
                        className={styles.searchInput}
                        type="text"
                        placeholder={labels.history.search.placeholder}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                    <Stack className={styles.chatHistoryListContainer}>
                        {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Success &&
                            appStateContext?.state.isCosmosDBAvailable.cosmosDB && <ChatHistoryList conversations={filteredConversations} />}
                        {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && appStateContext?.state.isCosmosDBAvailable && (
                            <>
                                <Stack>
                                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: "100%", marginTop: 10 }}>
                                        <StackItem>
                                            <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 16 }}>
                                                {appStateContext?.state.isCosmosDBAvailable?.status && (
                                                    <span>{appStateContext?.state.isCosmosDBAvailable?.status}</span>
                                                )}
                                                {!appStateContext?.state.isCosmosDBAvailable?.status && <span>{labels.history.loadingError}</span>}
                                            </Text>
                                        </StackItem>
                                        <StackItem>
                                            <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}>
                                                <span>{labels.history.cantSave}</span>
                                            </Text>
                                        </StackItem>
                                    </Stack>
                                </Stack>
                            </>
                        )}
                        {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading && (
                            <>
                                <Stack>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" style={{ width: "100%", marginTop: 10 }}>
                                        <StackItem style={{ justifyContent: "center", alignItems: "center" }}>
                                            <Spinner style={{ alignSelf: "flex-start", height: "100%", marginRight: "5px" }} size={SpinnerSize.medium} />
                                        </StackItem>
                                        <StackItem>
                                            <Text style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}>
                                                <span style={{ whiteSpace: "pre-wrap" }}>{labels.history.loading}</span>
                                            </Text>
                                        </StackItem>
                                    </Stack>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
            )}
            <Dialog
                hidden={hideClearAllDialog}
                onDismiss={clearing ? () => {} : onHideClearAllDialog}
                dialogContentProps={clearAllDialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    {!clearingError && <PrimaryButton onClick={onClearAllChatHistory} disabled={clearing} text={labels.history.clearAll} />}
                    <DefaultButton onClick={onHideClearAllDialog} disabled={clearing} text={!clearingError ? labels.cancel : labels.close} />
                </DialogFooter>
            </Dialog>
            {appStateContext?.state.isChatHistoryOpen && (
                <div ref={resizerRef} className={styles.resizer} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart} />
            )}
        </section>
    );
}
