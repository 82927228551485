import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";
import uuid from "react-uuid";
// todo: isn't for each already in JS / TS?
import { forEach } from "lodash-es";

import {
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    historyGenerate,
    historyUpdate,
    ChatMessage,
    ChatHistoryLoadingState,
    Conversation, ChatConversationLoadingState
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { Feedback } from "../../components/Feedback";
import { UserChatMessage } from "../../components/UserChatMessage";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { AppStateContext } from "../../state/AppProvider";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import ChatSettingsMenu from "../../components/ChatSettingsMenu/ChatSettingsMenu";
import i18n, {defaultLang} from "../../i18n";
// import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
// import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";

const Chat = () => {
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];
    const [isChatSettingsOpen, setIsChatSettingsOpen] = useState(false);
    /* NOTE: in the original the app, these settings where configurable,
    * but client decided not to provide these settings to users.
    * @see: https://github.com/Azure-Samples/azure-search-openai-demo  */
    const defaultSettings = {
        promptTemplate: "",
        excludeCategory: "",
        top: 5,
        retrievalMode: RetrievalMode.Hybrid,
        useSemanticRanker: true,
        shouldStream: true,
        useSemanticCaptions: false,
        useOidSecurityFilter: false,
        useGroupsSecurityFilter: false
    }
    const lastQuestionRef = useRef<string>("");
    const lastResponseRef = useRef<ChatAppResponse>();
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConversationLoading, setIsConversationLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    // const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    // const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const enum messageStatus {
        NotRunning = "Not Running",
        Processing = "Processing",
        Done = "Done"
    }
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"];

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setAnswers([...answers, [question, latestResponse]]);
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                }
            }
        } finally {
            appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
            setIsStreaming(false);
        }

        const finishReason = askResponse.choices[0]["finish_reason"];
        if (finishReason === "content_filter" || finishReason === "openai_error") {
            if (appStateContext?.state) {
                appStateContext.state.error = finishReason;
            }

            return {
                ...askResponse,
                choices: [{ ...askResponse.choices[0], message: { content: getErrorMessage(finishReason), role: askResponse.choices[0].message.role } }]
            };

        } else {
            return {
                ...askResponse,
                choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
            };
        }
    };

    const client = useLogin ? useMsal().instance : undefined;

    let assistantMessage = {} as ChatMessage;
    let toolMessage = {} as ChatMessage;
    let assistantContent = "";
    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content;
            assistantMessage = resultMessage;
            assistantMessage.content = assistantContent;
        }

        if (!conversationId) {
            setMessages([...messages, userMessage, assistantMessage]);
        } else {
            setMessages([...messages, assistantMessage]);
        }
    };

    const makeApiRequest = async (question: string, conversationId?: string) => {
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);
        appStateContext?.dispatch({ type: "SET_GENERATING", payload: true });
        if (appStateContext) appStateContext.state.error = undefined;
        // setActiveCitation(undefined);
        // setActiveAnalysisPanelTab(undefined);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const token = client ? await getToken(client) : undefined;
        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
            choices: []
        };

        try {
            let request: ChatAppRequest;
            let conversation;
            // maintaining the currentConversationId is required esp. when for follow-up questions are asked
            const currentConversationId = conversationId || appStateContext?.state.currentChat?.id;
            if (currentConversationId) {
                conversation = appStateContext?.state?.chatHistory?.find(conv => conv.id === currentConversationId);
                if (!conversation) {
                    console.error("Conversation not found.");
                    setIsLoading(false);
                    appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                } else {
                    conversation.messages.push(userMessage);
                    request = {
                        messages: [...conversation.messages],
                        stream: defaultSettings.shouldStream,
                        context: {
                            overrides: {
                                prompt_template: defaultSettings.promptTemplate.length === 0 ? undefined : defaultSettings.promptTemplate,
                                exclude_category: defaultSettings.excludeCategory.length === 0 ? undefined : defaultSettings.excludeCategory,
                                top: appStateContext?.state.retrieveCount,
                                retrieval_mode: defaultSettings.retrievalMode,
                                semantic_ranker: defaultSettings.useSemanticRanker,
                                semantic_captions: defaultSettings.useSemanticCaptions,
                                suggest_followup_questions: appStateContext?.state.enableFollowUpQuestions,
                                use_oid_security_filter: defaultSettings.useOidSecurityFilter,
                                use_groups_security_filter: defaultSettings.useGroupsSecurityFilter
                            }
                        },
                        // ChatAppProtocol: Client must pass on any session state received from the server
                        session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
                    };
                }
            } else {
                request = {
                    messages: [userMessage].filter(answer => answer.role !== ERROR),
                    stream: defaultSettings.shouldStream,
                    context: {
                        overrides: {
                            prompt_template: defaultSettings.promptTemplate.length === 0 ? undefined : defaultSettings.promptTemplate,
                            exclude_category: defaultSettings.excludeCategory.length === 0 ? undefined : defaultSettings.excludeCategory,
                            top: appStateContext?.state.retrieveCount,
                            retrieval_mode: defaultSettings.retrievalMode,
                            semantic_ranker: defaultSettings.useSemanticRanker,
                            semantic_captions: defaultSettings.useSemanticCaptions,
                            suggest_followup_questions: appStateContext?.state.enableFollowUpQuestions,
                            use_oid_security_filter: defaultSettings.useOidSecurityFilter,
                            use_groups_security_filter: defaultSettings.useGroupsSecurityFilter
                        }
                    },
                    // ChatAppProtocol: Client must pass on any session state received from the server
                    session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
                };
                setMessages([userMessage]);
            }

            const response = currentConversationId
                ? await historyGenerate(request, token?.accessToken, abortController.signal, currentConversationId)
                : await historyGenerate(request, token?.accessToken, abortController.signal);
            if (!response.body) {
                throw Error("No response body");
            }
            if (defaultSettings.shouldStream) {
                setProcessMessages(messageStatus.Processing);

                const parsedResponse: ChatAppResponse | false = await handleAsyncRequest(question, answers, setAnswers, response.body);
                lastResponseRef.current = await parsedResponse;

                const parsedChatResponse: ChatMessage = {
                    id: uuid(),
                    role: "assistant",
                    content: parsedResponse.choices[0].message.content,
                    date: new Date().toISOString(),
                    choices: parsedResponse.choices
                };

                setShowLoadingMessage(false);
                processResultMessage(parsedChatResponse, userMessage, currentConversationId);

                let resultConversation;
                if (currentConversationId) {
                    resultConversation = appStateContext?.state?.chatHistory?.find(conv => conv.id === currentConversationId);
                    if (!resultConversation) {
                        console.error("Conversation not found.");
                        setIsLoading(false);
                        appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(assistantMessage);
                } else {
                    resultConversation = {
                        id: parsedResponse.history_metadata.conversation_id,
                        title: parsedResponse.history_metadata.title,
                        messages: [userMessage],
                        date: parsedResponse.history_metadata.date,
                    };
                    resultConversation.messages.push(assistantMessage);
                }
                if (!resultConversation) {
                    setIsLoading(false);
                    appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: resultConversation });
                //setMessages([...messages, assistantMessage]);
                //setAnswers([...answers, [question, parsedResponse]]);
                setStreamedAnswers([...answers, [question, parsedResponse]]);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
                setStreamedAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
            }
        } catch (e) {
            console.error('Error in makeApiRequest', e);
            setError(e);
        } finally {
            setIsLoading(false);
            appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done);
        }
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing);
        lastQuestionRef.current = "";
        lastResponseRef.current = undefined;
        error && setError(undefined);
        setActiveCitation(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        setMessages([]);
        appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
        appStateContext?.dispatch({ type: "SET_GENERATING", payload: false });
        if (appStateContext?.state?.currentChat) {
            appStateContext.state.error = undefined;
        }
        setProcessMessages(messageStatus.Done);
    };

    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            const token = client ? await getToken(client) : undefined;
            const response = await historyUpdate(messages, token?.accessToken, id);
            return response;
        };
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            if (!appStateContext?.state.currentChat?.messages) {
                console.error("Failure fetching current chat state.");
                return;
            }
            saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
                .then(res => {
                    if (!res.ok) {
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: labels.chat.errorSaveToDB,
                            date: new Date().toISOString(),
                            choices: []
                        };
                        if (!appStateContext?.state.currentChat?.messages) {
                            let err: Error = {
                                ...new Error(),
                                message: "Failure fetching current chat state." // JavaScript error, no i18n required
                            };
                            throw err;
                        }
                        setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg]);
                    }

                    return res as Response;
                })
                .catch(err => {
                    console.error("Error: ", err);
                    let errRes: Response = {
                        ...new Response(),
                        ok: false,
                        status: 500
                    };
                    return errRes;
                });

            appStateContext?.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages);
            setProcessMessages(messageStatus.NotRunning);
            // console.log("MESSAGES LAYOUT EFFECT:", messages);
        }
    }, [processMessages]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);

    useEffect(() => {
        const isChatHistoryLoading = appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading;
        setIsLoading(isChatHistoryLoading);
        appStateContext?.dispatch({ type: "SET_GENERATING", payload: isChatHistoryLoading });
    }, [appStateContext?.state.chatHistoryLoadingState]);

    useEffect(() => {
        setIsConversationLoading(appStateContext?.state.chatConversationLoadingState === ChatConversationLoadingState.Loading);
    }, [appStateContext?.state.chatConversationLoadingState]);

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setProcessMessages(messageStatus.Processing);
            setMessages(appStateContext.state.currentChat.messages);
            // console.log("APP STATE C:", appStateContext);
            const restoredAnswers: [user: string, response: ChatAppResponse][] = convertMessagesToAnswers(appStateContext.state.currentChat);
            // todo: set answers or streamedAnswers depending on the settings
            setAnswers(restoredAnswers);
            setStreamedAnswers(restoredAnswers);

            // problem: as we do not have answers,
            // if we set them, the frontend may call useLayoutEffect and we enter an infinite loop
        } else {
            setMessages([]);
            setAnswers([]);
            setStreamedAnswers([]);
        }
    }, [appStateContext?.state.currentChat]);

/*    useEffect(() => {
        console.log("ANSWERS:", answers);
    }, [answers]);

    useEffect(() => {
        console.log("StreamedAnswers:", streamedAnswers);
    }, [streamedAnswers]);*/

    function convertMessagesToAnswers(currentChat: Conversation): [user: string, response: ChatAppResponse][] {
        let restoredAnswers: [user: string, response: ChatAppResponse][] = [];
        let currentChatMessages: ChatMessage[] = currentChat.messages;
        let userQuestions: string[] = [];
        let chatAppResponses: ChatAppResponse[] = [];

        forEach(currentChatMessages, (message: ChatMessage) => {
            if (message.role === "user") {
                userQuestions.push(message.content);
                // todo: beware: this is an unclean side effect as this function should only convert data.
                lastQuestionRef.current = message.content;
            } else if (message.role === "assistant") {
                const response = {
                    choices: message.choices ? message.choices : [],
                    history_metadata: {
                        conversation_id: currentChat.id,
                        title: currentChat?.title,
                        date: currentChat.date
                    }
                };
                // todo: beware: this is an unclean side effect as this function should only convert data.
                lastResponseRef.current = response;
                chatAppResponses.push(response);
            }
        });

        forEach(userQuestions, (question: string, index: number) => {
            restoredAnswers.push([question, chatAppResponses[index]]);
        });

        return restoredAnswers;
    }

    function getErrorMessage(finishReason: string): string {
        const errorMessages = labels.chat.errorMessages;
        type ErrorKeys = keyof typeof errorMessages;
        return finishReason as ErrorKeys in errorMessages
          ? errorMessages[finishReason as ErrorKeys]
          : errorMessages["generic_error"];
    }

    const onExampleClicked = (example: string) => {
        let conversationId = appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined;
        makeApiRequest(example, conversationId);
    };

    // const onShowCitation = (citation: string, index: number) => {
    //     if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
    //         setActiveAnalysisPanelTab(undefined);
    //     } else {
    //         setActiveCitation(citation);
    //         setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    //     }

    //     setSelectedAnswer(index);
    // };

    // const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    //     if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
    //         setActiveAnalysisPanelTab(undefined);
    //     } else {
    //         setActiveAnalysisPanelTab(tab);
    //     }
    //
    //     setSelectedAnswer(index);
    // };

    return (
        <div className={styles.appRoot}>
            {<ChatHistoryPanel newChat={newChat} newChatDisabled={!lastQuestionRef.current || !!appStateContext?.state.isGenerating} />}
            <div className={styles.appMainContent}>
                <div className={styles.appTitleContainer}>
                    <span className={styles.appTitle}>{labels.appTitle}</span>
                </div>
                {/* TODO: when enabling chatAnalysisPanel (only for developers) also add chatRoot again  */}
                {/*<div className={styles.chatRoot}>*/}
                    <div className={styles.chatContainer}>
                        <Feedback />
                        {!lastQuestionRef.current ? (
                            <div className={styles.chatEmptyState}>
                                <p className={styles.chatEmptyStateSubtitle}>{labels.chat.emptyStateSubtitle}:</p>
                                <ExampleList onExampleClicked={onExampleClicked} />
                            </div>
                        ) : (
                            <div className={styles.chatMessageStream}>
                                {isStreaming && !appStateContext?.state?.error &&
                                    streamedAnswers.map((streamedAnswer, index) => (
                                        <div key={index} className={styles.chatMessagesWrapper}>
                                            <UserChatMessage message={streamedAnswer[0]} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    isStreaming={true}
                                                    key={index}
                                                    answer={streamedAnswer[1]}
                                                    // isSelected={false}
                                                    // onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                    // onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                    onFollowupQuestionClicked={(question) => makeApiRequest(question)}
                                                    showFollowupQuestions={appStateContext?.state.enableFollowUpQuestions && answers.length - 1 === index}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                {!isStreaming &&!appStateContext?.state?.error &&
                                    answers.map((answer, index) => (
                                        <div key={index} className={styles.chatMessagesWrapper}>
                                            <UserChatMessage message={answer[0]} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    isStreaming={false}
                                                    key={index}
                                                    answer={answer[1]}
                                                    // isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                    // onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                    // onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                    onFollowupQuestionClicked={(question) => makeApiRequest(question)}
                                                    showFollowupQuestions={appStateContext?.state.enableFollowUpQuestions && answers.length - 1 === index}
                                                />
                                            </div>
                                        </div>
                                        ))}
                                {/* show error message */}
                                {!isStreaming && appStateContext?.state?.error &&
                                  <div className={styles.chatMessagesWrapper}>
                                      {/* Note: in case of an error, only the last question and the error are shown */}
                                      <UserChatMessage message={lastQuestionRef.current}/>
                                      <AnswerError error={appStateContext?.state?.error.toString()}
                                                   errorMessage={getErrorMessage(appStateContext?.state?.error.toString())}
                                                   onRetry={appStateContext?.state?.error === "content_filter" ? newChat : () => makeApiRequest(lastQuestionRef.current)}/>
                                  </div>
                                }
                                {isLoading && (
                                  <div className={styles.chatMessagesWrapper}>
                                      <UserChatMessage message={lastQuestionRef.current}/>
                                      <div className={styles.loadingContainer}>
                                          <div className={styles.chatMessageGptMinWidth}>
                                              <AnswerLoading/>
                                          </div>
                                      </div>
                                  </div>
                                )}
                                {/* show generic error */}
                                {error ? (
                                  <>
                                      <div className={styles.chatMessagesWrapper}>
                                          <UserChatMessage message={lastQuestionRef.current}/>
                                          <div className={styles.chatMessageGptMinWidth}>
                                              <AnswerError error={error.toString()}
                                                           errorMessage={getErrorMessage(error.toString())}
                                                               onRetry={() => makeApiRequest(lastQuestionRef.current)}/>
                                              </div>
                                          </div>
                                      </>
                                    ) : null}
                                            <div ref={chatMessageStreamEnd}/>
                                        </div>
                                    )}

                        <div className={styles.chatInput}>
                            <div className={styles.chatSettings}>
                                <ChatSettingsMenu buttonLabel={labels.chat.settings.buttonLabel} />
                            </div>
                            <QuestionInput
                              clearOnSend
                              placeholder={labels.chat.question.inputPlaceholder}
                              disabled={ !!appStateContext?.state.isGenerating }
                              onSend={(question, id) => makeApiRequest(question, id)}
                              conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                            />
                        </div>
                    </div>

                    {/* TODO: render analysis panel only for developers, if configured. Same for the lightbulb button in Answer.tsx */}
                   {/* {answers.length > 0 && activeAnalysisPanelTab && (
                        <AnalysisPanel
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers[selectedAnswer][1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )}*/}

                    {/*<Panel
                        headerText="Configure answer generation"
                        isOpen={isConfigPanelOpen}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                    >
                    </Panel>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Chat;
