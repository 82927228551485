const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, ChatMessage, Conversation, CosmosDBHealth, CosmosDBStatus } from "./models";
import { useLogin } from "../authConfig";

function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }
    return headers;
}

function handleHttpError(response: Response, responseData?: any) {
    if (response.status && response.status > 299 || !response.ok) {
        // if not authorized, redirect to login page
        if (response.status === 401 && responseData?.no_access_redirect_url) {
            window.location.href = responseData?.no_access_redirect_url;
        } else {
            throw Error(`Error ${response.status}: ${response.statusText || responseData?.error || "Unknown error"}`);
        }
    }
}

export async function validateUserToken(){
    try {
        const response = await fetch("/validate_user_token");
        const data = await response.json();
        handleHttpError(response, data);
    } catch (error) {
        console.error(error);
    }
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const data: ChatAppResponseOrError = await response.json();
    handleHttpError(response, data);

    return data as ChatAppResponse;
}

// not used anymore? remove?
export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

// not used anymore? remove?
export function getChunkFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export function getFullDocumentPath(citation: string): string {
    return `${BACKEND_URI}/full-document-content/${citation}`;
}

export async function getUsername(): Promise<string | null> {
    try {
        const response = await fetch(`${BACKEND_URI}/username`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await response.json();
        handleHttpError(response, data);

        return data?.username || null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getUserPictureUrl(): Promise<string | null> {
    try {
        const response = await fetch(`${BACKEND_URI}/userpicture`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await response.json();
        handleHttpError(response, data);

        return data?.picture || null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// Generating answers is limited to the latest 5 questions & answers mitigating token usage and performance issues
export const historyGenerate = async (options: ChatAppRequest, idToken: string | undefined, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;

    // Only send the latest 11 messages, sliding window of recent 5 questions & answers + current question
    const latestMessages = options.messages.slice(-11);

    if (convId) {
        body = JSON.stringify({
            context: options.context,
            messages: latestMessages,
            sessionState: options.session_state,
            stream: options.stream,
            conversation_id: convId
        });
    } else {
        body = JSON.stringify({
            context: options.context,
            messages: latestMessages,
            sessionState: options.session_state,
            stream: options.stream
        });
    }

    return await fetch(`${BACKEND_URI}/history/generate`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: body,
        signal: abortSignal
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return new Response();
        });
};

export const historyUpdate = async (messages: ChatMessage[], idToken: string | undefined, convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: getHeaders(idToken)
    })
        .then(async res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            };
            return errRes;
        });

    const data = await response.json();
    handleHttpError(response, data);

    return response;
};

// NOTE: for better loading performance and reducing costs, only fetch the list of conversations, without messages.
// Messages are only fetched by "/history/read" when the user clicks on a conversation
export const historyList = async (): Promise<Conversation[] | null> => {
    try {
        const response = await fetch("/history/list", {
            method: "GET"
        });

        const data = await response.json();
        if (!Array.isArray(data)) {
            console.error("There was an issue fetching your data.");
            return null;
        }

        return await Promise.all(
          data.map(async (conv: any) => {
              const conversation: Conversation = {
                  id: conv.id,
                  title: conv.title,
                  date: conv.createdAt,
                  messages: [] // empty messages
              };
              return conversation;
          })
        );
    } catch (error) {
        console.error(error);
        return null;
    }
};

// get the full conversation by id, including all messages
export const historyRead = async (convId: string): Promise<Conversation | null> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(async res => {
            if (!res) {
                return null;
            }
            const payload = await res.json();
            if (payload) {
                let messages: ChatMessage[] = [];
                payload.messages.forEach((msg: any) => {
                    const message: ChatMessage = {
                        id: msg.id,
                        role: msg.role,
                        date: msg.createdAt,
                        content: msg.content,
                        choices: msg.choices
                    };
                    messages.push(message);
                });
                // note: title and date are not provided by /history/read
                return {
                    id: convId,
                    title: "",
                    messages: payload.messages,
                    date: ""
                };
            } else {
                return null;
            }
        })
        .catch(err => {
            console.error("There was an issue fetching conversation data from history.", err);
            return null;
        });
    // console.log("FETCH CHAT HISTORY CONVERSATION: ", response);
    return response;
};

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET"
    })
        .then(async res => {
            let respJson = await res.json();
            let formattedResponse;
            if (respJson.message) {
                formattedResponse = CosmosDBStatus.Working;
            } else {
                if (res.status === 500) {
                    formattedResponse = CosmosDBStatus.NotWorking;
                } else {
                    formattedResponse = CosmosDBStatus.NotConfigured;
                }
            }
            if (!res.ok) {
                return {
                    cosmosDB: false,
                    status: formattedResponse
                };
            } else {
                return {
                    cosmosDB: true,
                    status: formattedResponse
                };
            }
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            return {
                cosmosDB: false,
                status: err
            };
        });
    return response;
};

export const historyDelete = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            };
            return errRes;
        });

    const data = await response.json();
    handleHttpError(response, data);
    return response;
};

export const historyDeleteAll = async (idToken: string | undefined): Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: getHeaders(idToken)
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            };
            return errRes;
        });

    const data = await response.json();
    handleHttpError(response, data);
    return response;
};

export const historyClear = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            };
            return errRes;
        });
    const data = await response.json();
    handleHttpError(response, data);
    return response;
};

export const historyRename = async (convId: string, title: string): Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            };
            return errRes;
        });
    const data = await response.json();
    handleHttpError(response, data);
    return response;
};

export async function logout(): Promise<void> {
    try {
        const response = await fetch(`${BACKEND_URI}/logout`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        });

        const data = await response.json();
        if (data?.logout_url) {
            window.location.href = data.logout_url;
        }
    } catch (error) {
        console.error(`Error: ${error}`);
    }
}
