import { Button } from "@fluentui/react-components";
import styles from "./ChatItemButton.module.css";
import {ButtonProps} from "@fluentui/react-button";
import classNames from "classnames";

interface Props {
    className?: string;
    onClick: () => void;
    title?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
    icon: React.ReactElement;
    ariaLabel?: string;
    showLabel?: boolean;
    disabled?: boolean;
    appearance?: ButtonProps["appearance"];
}

const ChatItemButton = ({ className, onClick, title, onKeyDown, icon, ariaLabel, showLabel, disabled, appearance }: Props) => {
    const buttonClass = classNames(className, styles.chatItemButton, {
        [styles.outline]: appearance === "outline",
        [styles.disabled]: disabled,
        [styles.showLabel]: showLabel,
    });

    return <Button onClick={onClick} icon={icon} onKeyDown={onKeyDown} title={!showLabel ? title : ''} className={buttonClass} aria-label={!showLabel ? ariaLabel: ''} disabled={disabled}>
        {showLabel ? title : null}
    </Button>;
};

export default ChatItemButton;
