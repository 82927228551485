/**
 * These utility functions enable to register and unregister multiple event listeners on the document object.
 * This prevents overwriting or removing existing document event handlers of other components.
 */

type EventHandler<EventType extends Event> = (event: EventType) => void;
const eventHandlers: { [key: string]: EventHandler<Event>[] } = {};

/**
 * Register and add an event listener to the document.
 * @param type
 * @param handler
 */
export function addEventListener<EventType extends Event>(type: string, handler: EventHandler<EventType>): void {
  if (!eventHandlers[type]) {
    eventHandlers[type] = [];
    document.addEventListener(type, (event) => {
      eventHandlers[type].forEach((handler) => handler(event as EventType));
    });
  }
  eventHandlers[type].push(handler as EventHandler<Event>);
}

/**
 * Remove an event listener from the register and document
 * @param type
 * @param handler
 */
export function removeEventListener<EventType extends Event>(type: string, handler: EventHandler<EventType>): void {
  if (eventHandlers[type]) {
    eventHandlers[type] = eventHandlers[type].filter((h) => h !== handler);
  }
}