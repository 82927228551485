import { Example } from "./Example";

import styles from "./Example.module.css";
import {AppStateContext} from "../../state/AppProvider";
import {useContext} from "react";
import i18n, {defaultLang} from "../../i18n";

export type ExampleModel = {
    text: string;
    value: string;
};

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];
    return (
        <ul className={styles.examplesNavList}>
            {labels.chat.exampleQuestions.map((question: string, index: number) => (
                <li key={index}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
