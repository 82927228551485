const logoutIcon =
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1016_975)">
      <path d="M2.21234 7.96383H10.3341C10.5934 7.96383 10.8029 7.75383 10.8029 7.49508C10.8029 7.23633 10.5934 7.02633 10.3341 7.02633H2.24164L3.93734 5.33062C4.12039 5.14758 4.12039 4.85086 3.93734 4.66781C3.7543 4.48477 3.45758 4.48477 3.27453 4.66781L0.505859 7.50961L3.27453 10.3514C3.36617 10.443 3.48594 10.4887 3.60594 10.4887C3.72594 10.4887 3.8457 10.443 3.93734 10.3514C4.12039 10.1684 4.12039 9.87164 3.93734 9.68859L2.21234 7.96383ZM14.5585 0H6.58975C6.07178 0 5.65225 0.419766 5.65225 0.9375V5.15625H6.59584V1.50914C6.59584 1.19602 6.84991 0.941953 7.16303 0.941953H13.9705C14.2838 0.941953 14.5376 1.19602 14.5376 1.50914L14.5524 13.4958C14.5524 13.8089 14.2986 14.063 13.9852 14.063H7.16328C6.85016 14.063 6.59609 13.8089 6.59609 13.4958V9.82828L5.6525 9.82945V14.0625C5.6525 14.5802 6.07203 15 6.59 15H14.5585C15.0765 15 15.4963 14.5802 15.4963 14.0625V0.9375C15.496 0.419766 15.0762 0 14.5585 0Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1016_975">
        <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>

export default logoutIcon;