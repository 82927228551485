import React, { useContext, useEffect } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { addEventListener, removeEventListener } from "../../utils/documentEventHandlers";
import { Button } from "@fluentui/react-components";
import classNames from "classnames";
import { logout } from "../../api";
import styles from "./UserMenu.module.css";
import logoutIcon from "../LogoutIcon/LogoutIcon";
import userIcon from "../UserIcon/UserIcon";
import i18n, { defaultLang } from "../../i18n";

interface Props {
    triggerRef: React.RefObject<HTMLAnchorElement>;
}

export const UserMenu = ({ triggerRef }: Props) => {
    const userMenuRef = React.createRef<HTMLElement>();
    const appStateContext = useContext(AppStateContext);
    const labels = i18n[appStateContext?.state.lang || defaultLang];

    const closeUserMenu = () => {
        appStateContext?.dispatch({ type: "USER_MENU_OPEN", payload: false });
    };

    const handleEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            closeUserMenu();
        }
    };

    const handleUserMenuBlur = (event: MouseEvent) => {
        if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node) && !triggerRef.current?.contains(event.target as Node)) {
            closeUserMenu();
        }
    };

    const showDisclaimerModal = () => {
        appStateContext?.dispatch({ type: "DISCLAIMER_MODAL_OPEN", payload: true });
    };

    // close menu on ESC key or click outside the menu
    useEffect(() => {
        // prevent adding event listener if the menu is not open
        if (!userMenuRef.current || !appStateContext?.state.isUserMenuOpen) return;

        addEventListener("mousedown", handleUserMenuBlur);
        addEventListener("keydown", handleEscape);

        return () => {
            removeEventListener("mousedown", handleUserMenuBlur);
            removeEventListener("keydown", handleEscape);
        };
    }, [userMenuRef]);

    return (
        <nav
            className={classNames(styles.userMenu, {
                [styles.open]: appStateContext?.state.isUserMenuOpen
            })}
            ref={userMenuRef}
        >
            <a href={labels.userMenu.profileUrl}>
                {userIcon}
                {labels.userMenu.profile}
            </a>

            <Button
                className={styles.logoutButton}
                icon={logoutIcon}
                onClick={logout}
            >
                {labels.userMenu.logout}
            </Button>

            <ul className={styles.linkList}>
                <li>
                    <a href={labels.userMenu.links.sitePolicyUrl}>{labels.userMenu.links.sitePolicy}</a>
                </li>
                <li>
                    <a href={labels.userMenu.links.privacyPolicyUrl}>{labels.userMenu.links.privacyPolicy}</a>
                </li>
                <li>
                    <a href={labels.userMenu.links.imprintUrl}>{labels.userMenu.links.imprint}</a>
                </li>
                <li>
                    <a href="javascript:" onClick={showDisclaimerModal}>
                        {labels.userMenu.links.disclaimer}
                    </a>
                </li>
            </ul>
        </nav>
    );
};
