import { Stack, PrimaryButton } from "@fluentui/react";
import Logo from "../../assets/logo_xs.svg";

import styles from "./Answer.module.css";

import errorIcon from "../../assets/error.svg";
import {useContext} from "react";
import {AppStateContext} from "../../state/AppProvider";
import i18n, {defaultLang} from "../../i18n";

interface Props {
    error: string;
    onRetry: () => void;
    errorMessage?: string;
}

export const AnswerError = ({ error, onRetry, errorMessage}: Props) => {
  const appStateContext = useContext(AppStateContext);
  const labels = i18n[appStateContext?.state.lang || defaultLang];

    const buttonLabel: string = (error == "content_filter" ? labels.startNewChat : labels.tryAgain);
    return (
        <div className={styles.answerAndIdentifierContainer}>
            <div className={styles.answerIdentifier}>
                <img src={Logo} className={styles.logoIcon} />
            </div>
            <Stack className={styles.answerContainer}>
                <Stack.Item grow>
                    <p className={styles.answerText} style={{padding: 0}}>
                        {errorMessage}
                        <br/>
                        <a className={styles.retryLink} onClick={onRetry}  style={{ paddingTop: "5px" }}>
                           {buttonLabel}
                        </a>
                    </p>
                </Stack.Item>
            </Stack>
            <div className={styles.answerErrorIcon}>
                <img src={errorIcon} alt={labels.errorMessage} />
            </div>
        </div>
    );
};
