import {useContext, useEffect, useRef, useState} from "react";
import {addEventListener, removeEventListener} from "../../utils/documentEventHandlers";
import styles from "./DisclaimerModal.module.css";
import {Button} from "@fluentui/react-components";
import disclaimerIcon from "../DisclaimerIcon/DisclaimerIcon";
import {AppStateContext} from "../../state/AppProvider";

const DISCLAIMER_COOKIE = "themis-ai-disclaimer-accepted=true";

/**
 * This modal has two behaviours based on DISCLAIMER_COOKIE:
 * - if not accepted, the user has to accept before continuing.
 * - if accepted, the user can close the modal by clicking on close button, the curtain or pressing ESC key.
 * @constructor
 */
export const DisclaimerModal = () => {
  const appStateContext = useContext(AppStateContext);
  const [showModal, setShowModal] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const curtainRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isAccepted = document.cookie.includes(DISCLAIMER_COOKIE);
    const showModal = (!isAccepted || appStateContext?.state.isDisclaimerModalOpen || false);
    setIsAccepted(isAccepted);
    setShowModal(showModal);
  }, [appStateContext?.state.isDisclaimerModalOpen])

  useEffect(() => {
    if (isAccepted && showModal) {
      addEventListener("keydown", handleEscape);
      return () => {
        removeEventListener("keydown", handleEscape);
      }
    }
  }, [isAccepted, showModal]);

  const closeModal = () => {
    setShowModal(false);
    appStateContext?.dispatch({ type: "DISCLAIMER_MODAL_OPEN", payload: false });
  }

  const handleAcceptClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (curtainRef.current) {
      curtainRef.current.style.display = 'none';
      document.cookie = DISCLAIMER_COOKIE;
      setIsAccepted(true);
      closeModal();
    }
  }

  // if accepted, disclaimer can be closed by clicking the curtain
  const handleCurtainClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent> ) => {
    if (isAccepted && event.target === curtainRef.current) {
      closeModal();
    }
  }

  // if accepted, disclaimer can be closed by ESC key
  const handleEscape = (e: KeyboardEvent) => {
    if (isAccepted && e.key === "Escape") {
      closeModal();
    }
  };

  if (!showModal) return null;

  /* TODO: this text contend should also be integrated in i18n
      eg. as "de_diclaimerContent.tsx" */
  return <div className={styles.disclaimerCurtain} ref={curtainRef} onClick={handleCurtainClick}>
      <div className={styles.disclaimerModal}>
        <div className={styles.disclaimerContent} ref={contentRef}>
          <h2 className={styles.disclaimerTitle}>{disclaimerIcon}Disclaimer und Haftungsausschluss für AI-powered THEMIS Chatbot</h2>
          <h3>1. Allgemeine Hinweise</h3>
          <p>Sie greifen auf eine erste Version des AI-powered THEMIS Chatbot zu, der auf einem KI-gestützten Sprachmodell (GPT-4o)
            basiert. Der Chatbot unterstützt bei der Informationsrecherche zu Abschlussberichten von Forschungsprojekten
            der Forschungsvereinigung Antriebstechnik e.V. (FVA). Bitte bestätigen Sie Ihr Verständnis und Ihre
            Zustimmung
            zu den folgenden Punkten, bevor Sie fortfahren:</p>
          <h3>2. Keine Gewähr für Richtigkeit und Vollständigkeit</h3>
          <ul>
            <li>Ich verstehe, dass die von der KI generierten Antworten nicht garantiert zu 100 % korrekt, vollständig oder
              aktuell sind. Auch wenn eine Wissensquelle zur Verfügung gestellt wurde, kann die Ausgabe Abweichungen
              enthalten oder Informationen hinzufügen oder verändern, die nicht vollständig durch die Quelle gedeckt
              sind.</li>
            <li>Trotz sorgfältiger Entwicklung und kontinuierlicher Optimierung kann nicht garantiert werden, dass alle
              Antworten stets korrekt, aktuell oder vollständig sind. Die Nutzung der bereitgestellten Informationen
              erfolgt daher auf eigenes Risiko.</li>
          </ul>
          <h3>3. Keine rechtsverbindlichen Auskünfte</h3>
          <ul>
            <li>Die generierten Antworten stellen keine verbindliche oder abschließende Beratung dar und ersetzen nicht
              die
              individuelle Beratung durch Fachleute.
            </li>
            <li>Alle Inhalte müssen vor der Nutzung auf Richtigkeit, Vollständigkeit und Relevanz geprüft werden.</li>
          </ul>
          <h3>4. Urheberrechte und Offenlegungspflichten</h3>
          <ul>
            <li>Ich verstehe, dass ich selbst dafür verantwortlich bin, alle Urheberrechte oder Nutzungsrechte an
              Inhalten,
              insbesondere an Bildern oder Grafiken, zu überprüfen und sicherzustellen, dass eine Nutzung gemäß den
              geltenden gesetzlichen Bestimmungen zulässig ist.
            </li>
            <li>Es liegt in meiner Verantwortung, die erforderlichen Offenlegungspflichten zu erfüllen, falls eine durch
              generative KI erstellte Antwort oder ein Bild als solches gekennzeichnet werden muss.
            </li>
          </ul>
          <h3>5. Haftungsausschluss</h3>
          <p>Die FVA übernimmt keine Haftung für Schäden oder Nachteile, die aus der Nutzung der vom Chatbot
            bereitgestellten Informationen entstehen. Dies gilt insbesondere für mittelbare oder unmittelbare Schäden,
            Folgeschäden, entgangenen Gewinn oder sonstige wirtschaftliche Verluste, die durch fehlerhafte,
            unvollständige oder missverständliche Angaben entstehen können.</p>
          <h3>6. Nutzung auf eigene Verantwortung</h3>
          <p>Ich übernehme die volle Verantwortung für die Überprüfung aller Daten, Fakten, Quellen und die Qualität der
            generierten Inhalte, bevor ich sie verwende oder weiterverbreite.</p>
          <p>Durch die Nutzung des Chatbots erklärt sich der Nutzer damit einverstanden, die bereitgestellten
            Informationen eigenverantwortlich zu verwenden und sie – insbesondere bei wichtigen Entscheidungen – durch
            weitere Quellen oder Expertenmeinungen zu validieren.</p>
          <h3>7. Weiterentwicklung und Verbesserung</h3>
          <p>Die FVA arbeitet kontinuierlich an der Verbesserung des Chatbots und nimmt Nutzerfeedback ernst. Falls Sie
            auf fehlerhafte oder unklare Informationen stoßen, können Sie uns diese über feedback-ki-chatbot@fva-net.de
            melden, damit wir die Qualität der bereitgestellten Inhalte weiter optimieren können.</p>
          <h3>8. Änderungen und Aktualisierungen</h3>
          <p>Dieser Disclaimer kann bei Bedarf aktualisiert werden. Nutzer werden daher gebeten, sich regelmäßig über
            Änderungen zu informieren.
            <br/><br/>
            Stand: März 2025</p>
        </div>
        {isAccepted ?
            <div className={styles.buttonContainer}>
                <Button className={styles.acceptButton} onClick={closeModal}>Schließen</Button>
            </div>
            :
            <div className={styles.buttonContainer}>
                <Button className={styles.cancelButton} onClick={() => location.href = 'https://www.themis-wissen.de'}>Zurück</Button>
                <Button className={styles.acceptButton} onClick={handleAcceptClick}>Ich verstehe und bin einverstanden</Button>
            </div>}
      </div>
    </div>
};