import styles from "./PageHeader.module.css";
import {Link, NavLink} from "react-router-dom";
import Logo from "../../assets/logo_s.svg";
import React, {RefObject, useContext, useState} from "react";
import {UserMenu} from "../UserMenu";
import {AppStateContext} from "../../state/AppProvider";
import userIcon from "../UserIcon/UserIcon";
import i18n, {defaultLang} from "../../i18n";

export const PageHeader = () => {
  const appStateContext = useContext(AppStateContext);
  const labels = i18n[appStateContext?.state.lang || defaultLang];
  const userMenuTriggerRef: RefObject<HTMLAnchorElement> = React.createRef<HTMLAnchorElement>();

  return (<header className={styles.header} role={"banner"} >
    <div className={styles.headerContainer}>
      <Link to={labels.pageHeader.themisUrl} className={styles.headerTitleContainer}>
        <img src={Logo} className={styles.logoIcon}/>
      </Link>
      <nav className={styles.headerNav}>
        <NavLink to="javascript:" className={`${styles.headerNavPageLink} ${styles.user}`} ref={userMenuTriggerRef}
                 onClick={(e) => {
                    e.preventDefault();
                    // toggle isUserMenuOpen state
                    appStateContext?.dispatch({ type: "USER_MENU_OPEN", payload: !appStateContext?.state.isUserMenuOpen });
                 }}
        >
          {appStateContext?.state.userName || null}
          {appStateContext?.state.userPicture
            ? <img src={appStateContext?.state.userPicture} className={styles.userPicture}/>
            : <div className={styles.userIcon}>{userIcon}</div>}
        </NavLink>
      </nav>
    </div>
    <UserMenu triggerRef={userMenuTriggerRef}/>
  </header>);
}